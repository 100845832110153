import React, { useContext } from "react"
import { graphql } from "gatsby"
import { LocationContext } from "../contexts/LocationContext"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Breadcrumbs from "../components/Breadcrumbs"
import Table from "../components/Table"
import InternalCards from "../components/InternalCards"
import Cta from "../components/Cta"
import CtaHeading from "../components/CtaHeading"
import Ready from "../components/Ready"

export const LocationsPageTemplate = ({
  title,
  subtitle,
  categories,
  data,
  cta,
  breadcrumbs,
  loc,
}) => (
  <>
    <Header title={title} subtitle={subtitle}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 35">
        <path d="M234.012 321.818c-63.706-1.08-152.387 19.025-194.214 9.917-42.023-9.068-37.153-47.152-54.561-89.467-17.212-42.355-18.331-175.223 6.097-217.61 24.449-42.288 65.35 30.097 159.613-8.693 78.144-32.156 162.665 19.7 252.685 14.52 90.02-5.178 190.041-37.023 209.322-20.164 19.28 16.86-42.16 82.522-7.653 125.613 34.488 42.993 164.943 63.513 165.175 85.185.212 21.573-129.974 44.437-217.561 69.014-87.587 24.577-132.789 50.809-174.647 52.822-42.055 2.052-80.55-20.056-144.256-21.137z" />
      </svg>
    </Header>
    <Breadcrumbs data={breadcrumbs} />
    <Table categories={categories} data={data} />
    {loc && (
      <>
        <CtaHeading
          title={`Are you a charity in ${loc.city}, ${loc.province}?`}
          subtitle="Sign up here and start getting recycling donations!"
        />
        <Cta
          variant
          href="https://app.skipthedepot.com/register?tab=organization"
        >
          Sign up
        </Cta>
      </>
    )}

    <CtaHeading title={cta.title} subtitle={cta.subtitle} />
    <Cta variant to={cta.url}>
      {cta.cta}
    </Cta>
    <InternalCards recycling services fundraising />
    <Ready>
      <Cta href="https://app.skipthedepot.com/register">Get started</Cta>
      <Cta to="/contact" variant>
        Contact us
      </Cta>
    </Ready>
  </>
)

const LocationsPage = ({
  data: {
    markdownRemark: {
      frontmatter: { title, subtitle, seoDesc, categories, data, cta },
    },
  },
  location,
}) => {
  const breadcrumbs = location.pathname.split("/")
  const { loc } = useContext(LocationContext)

  return (
    <Layout
      title={`Recycling Pickup Locations | SkipTheDepot | Book A Pickup Today`}
      seoDesc={seoDesc}
    >
      <LocationsPageTemplate
        title={title}
        subtitle={subtitle}
        categories={categories}
        data={data}
        cta={cta}
        breadcrumbs={breadcrumbs}
        loc={loc}
      />
    </Layout>
  )
}

export default LocationsPage

export const PageQuery = graphql`
  query locationsQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "locations-page" } }) {
      frontmatter {
        title
        subtitle
        seoDesc
        categories
        data {
          alberta {
            city
            services
          }
        }
        cta {
          title
          subtitle
          cta
          url
        }
      }
    }
  }
`
